import { createApp } from 'vue';
import Menu from './components/Menu.vue';
import Featured from './components/Featured.vue';

const components = {
  'data-vue-menu': Menu,
  'data-vue-featured': Featured
};

Object.entries(components).forEach(([selector, component]) => {
  const element = document.querySelector(`[${selector}]`);
  if (element) {
    createApp(component).mount(element);
  }
});

if (import.meta.hot) {
  import.meta.hot.accept();
}
