<template>
  <div
    ref="embla"
    class="pointer-events-none overflow-hidden"
    style="
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 10%,
        rgb(0, 0, 0) 90%,
        rgba(0, 0, 0, 0) 100%
      );
    "
  >
    <div class="flex">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/the-telegraph.svg`" alt="The Telegraph">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/bbc.svg`" alt="BBC">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/the-times.svg`" alt="The Times">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/bloomberg.svg`" alt="Bloomberg">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/financial-times.svg`" alt="Financial Times">
      <img class="flex-none max-w-64 max-h-9 mx-12" :src="`/assets/featured/wired.svg`" alt="Wired">
    </div>
  </div>
</template>

<script setup>
import emblaCarouselVue from 'embla-carousel-vue';
import AutoScroll from 'embla-carousel-auto-scroll';

const [embla] = emblaCarouselVue({ loop: true }, [AutoScroll({ startDelay: 0, speed: 0.75 })]);
</script>
